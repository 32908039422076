import styled from 'styled-components'

const ShopSmallSectionStyle = styled.div.attrs((props) => ({}))`
	background-color: var(--lightWhite);
	padding: var(--sp7x) var(--sp13x);
	justify-content: space-between;
	position: relative;
    height: ${props => props.contHeight} !important;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 1px;
		height: 100%;
		background-color: var(--black);
	}

	.second {
		margin: var(--sp2x) 0;
		position: relative;
		align-self: flex-start;

		&.animPlay {
			svg path {
				stroke-dashoffset: 0;
			}
		}

		svg {
			position: absolute;
			top: 55%;
			left: 50%;
			transform: translate(-50%, -50%) !important;
			transition-delay: 0s !important;
			width: 110%;
			height: auto;

			path {
				stroke-dasharray: 4700;
				stroke-dashoffset: -4700;
				transition: 1s 1.2s;
			}
		}
	}

	.imageCont {
		width: 100%;
		padding-top: 70%;
		transform: scale(1.2);
		transition: 1.5s cubic-bezier(0.76, 0, 0.24, 1);

		&.animPlay {
			transform: scale(1);
		}

		img {
			object-fit: contain !important;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		padding: var(--sp6x) var(--sp8x);

		.second {
			margin: var(--spSize) 0;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}) {
		padding: var(--sp5x) var(--sp7x);

		.second {
			margin: var(--spSize) 0;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		padding: var(--sp5x) var(--sp8x);
		&:before {
			display: none;
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
			padding: var(--sp6x) var(--sp4x) var(--sp5x) var(--sp4x);
		}

		.imageCont {
			position: absolute;
			top: 0;
			right: 0;
			width: 54%;
			padding-top: 40%;
		}

		.homeBtn {
			align-self: flex-start;
			margin-top: var(--sp7x);
		}

        .mainTitle {
            margin-top: var(--sp3x);
        }
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		padding: var(--sp6x) var(--sp2x) var(--sp12x) var(--sp2x);

		.mainTitle {
			margin-top: var(--sp10x);
		}

		&:before {
			display: none;
		}

		.imageCont {
			position: relative;
			width: 100%;
			padding-top: 80%;
		}

		.homeBtn {
			align-self: flex-start;
			margin-top: 0;
		}
	}
`

export default ShopSmallSectionStyle

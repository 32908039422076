import { memo as Memo, useMemo, useEffect, useState, useCallback, useRef } from 'react'
import { useRouter } from 'next/router'

//* HOCs
import { withDataContext, withUIContext } from 'context'

//* Components
import Page from 'components/common/Page'
import Text from 'components/common/Text'
import HomeSection from 'components/pages/Home/HomeSection'
import AboutUsSection from 'components/pages/Home/AboutUsSection'
import ShopSection from 'components/pages/Home/ShopSection'
import BlogSection from 'components/pages/Home/BlogSection'
import FooterSection from 'components/pages/Home/FooterSection'
import ShopSmallSection from 'components/pages/Home/ShopSmallSection'
import PagesMenu from 'components/pages/Home/PagesMenu'

//* Style
import HomeStyle from 'styles/pages/HomeStyle'
import HomeTabletStyle from 'styles/pages/HomeTabletStyle'

import { LocomotiveScrollProvider } from 'react-locomotive-scroll'

const Home = Memo(
    ({
        pages,
        pageParams,
        selectedLang,
        winWidth,
        makeHeaderBlack,
        makeHeaderWhite,
        shopSmallWidth,
        getPageOffsetScroll,
        pageOffsetScrollLeft,
        shopSmallSectionHeight,
        global,
        isLogoClicked,
        ...props
    }) => {
        const router = useRouter()
        const homeSecRef = useRef()
        const aboutSecRef = useRef()
        const shopSecRef = useRef()
        const blogSecRef = useRef()
        const footerSecRef = useRef()
        const shopSmallSecRef = useRef()
        const containerRef = useRef(null)

        //! State
        const [activeSection, setActiveSection] = useState(0)
        const [sections, setSections] = useState([])

        //! Page Data
        const pageData = useMemo(() => pages?.[pageParams.name]?.[selectedLang], [pages, selectedLang, pageParams.name])

        //! Global
        const globalData = useMemo(() => global?.[selectedLang], [global, selectedLang])

        useEffect(() => {
            setSections([
                shopSmallSecRef.current?.current?.clientWidth,
                homeSecRef.current?.clientWidth,
                aboutSecRef.current?.clientWidth,
                shopSecRef.current?.clientWidth,
                blogSecRef.current?.clientWidth,
                footerSecRef.current?.clientWidth,
            ])
        }, [
            shopSmallSecRef.current?.current?.clientWidth,
            homeSecRef.current?.clientWidth,
            aboutSecRef.current?.clientWidth,
            shopSecRef.current?.clientWidth,
            blogSecRef.current?.clientWidth,
            footerSecRef.current?.clientWidth,
            pageData,
            globalData,
        ])

        const calcItems = useCallback(
            (arr, index) => {
                let sum = 0

                for (let i = 0; i <= index; i++) {
                    sum += arr[i]
                }

                return sum
            },
            [sections]
        )

        const onScroll = (e) => {
            getPageOffsetScroll(e.scroll.x)

            let fullWdt =
                shopSmallSecRef.current?.current?.clientWidth +
                homeSecRef?.current?.clientWidth +
                aboutSecRef?.current?.clientWidth +
                shopSecRef?.current?.clientWidth +
                blogSecRef?.current?.clientWidth +
                footerSecRef?.current?.clientWidth

            if (
                shopSmallSecRef.current?.current?.clientWidth + homeSecRef?.current?.clientWidth + aboutSecRef?.current?.clientWidth < e.scroll.x &&
                fullWdt - footerSecRef?.current?.clientWidth > e.scroll.x
            ) {
                makeHeaderBlack()
            } else {
                makeHeaderWhite()
            }

            let wdt
            for (let i = 1; i < sections.length; i++) {
                wdt = calcItems(sections, i)
                if (e.scroll.x + 100 < wdt) {
                    setActiveSection(i)
                    return
                }
            }
        }

        useEffect(() => {
            onScroll({ scroll: { x: 0 } })
        }, [sections])

        //!logics under 1280

        const scrollOld = useRef(0)

        const handleNavigation = useCallback(
            (e) => {
                const window = e.currentTarget
                if (scrollOld.current < window.scrollY) {
                    if (window.scrollY > shopSmallSectionHeight) {
                        if (!document.getElementsByTagName('header')[0].classList.contains('fixed')) {
                            document.getElementsByTagName('header')[0].classList.add('fixed')
                        }
                    }
                } else if (scrollOld.current > window.scrollY) {
                    if (window.scrollY <= shopSmallSectionHeight) {
                        document.getElementsByTagName('header')[0].classList.remove('fixed')
                    }
                }

                scrollOld.current = window.scrollY
            },
            [scrollOld.current, shopSmallSectionHeight]
        )

        useEffect(() => {
            scrollOld.current = window.scrollY
            window.addEventListener('scroll', handleNavigation)

            return () => {
                window.removeEventListener('scroll', handleNavigation)
            }
        }, [handleNavigation])

        const storeHomeDependScreen = useMemo(() => {
            if (winWidth < 1280) {
                return pageData && globalData ? (
                    <HomeTabletStyle>
                        <Page className='home FlexBoxColumn' meta={pageData.content.meta}>
                            <LocomotiveScrollProvider>
                                <div className='FlexBoxColumn' data-scroll-container>
                                    <ShopSmallSection ref={shopSmallSecRef} />
                                    <HomeSection ref={homeSecRef} activeSection={activeSection} homeText={pageData.content.hero_text} />
                                    <AboutUsSection
                                        ref={aboutSecRef}
                                        activeSection={activeSection}
                                        aboutData={pageData.content.about}
                                        madeIn={pageData.content.made_in}
                                    />
                                    <ShopSection
                                        ref={shopSecRef}
                                        activeSection={activeSection}
                                        shopText={pageData.content.shop_text}
                                        products={pageData.products}
                                    />
                                    <BlogSection
                                        ref={blogSecRef}
                                        activeSection={activeSection}
                                        blogs={pageData.posts}
                                        blogText={pageData.content.blog_text}
                                        post={pageData.posts}
                                    />
                                    <FooterSection ref={footerSecRef} activeSection={activeSection} data={globalData} />
                                </div>
                            </LocomotiveScrollProvider>
                        </Page>
                    </HomeTabletStyle>
                ) : null
            } else {
                return pageData && globalData ? (
                    <HomeStyle>
                        <Page className='home' meta={pageData.content.meta}>
                            <LocomotiveScrollProvider
                                options={{
                                    smooth: true,
                                    direction: 'horizontal',
                                    getSpeed: true,
                                    tablet: {
                                        smooth: true,
                                        direction: 'horizontal',
                                        gestureDirection: 'horizontal',
                                        breakpoint: 1279
                                    },
                                }}
                                watch={[router.asPath]}
                                containerRef={containerRef}
                                location={selectedLang}
                                onLocationChange={(scroll) => scroll.scrollTo(-100, { duration: 0, disableLerp: true })}
                                onUpdate={(scroll) => scroll.on('scroll', onScroll)}
                            >
                                <div
                                    className='home-hdr FlexBoxColumn spaceBetween'
                                    style={{
                                        transform:
                                            pageOffsetScrollLeft < shopSmallWidth
                                                ? `translateX(${shopSmallWidth - pageOffsetScrollLeft}px)`
                                                : 'translateX(0)',
                                    }}
                                >
                                    <div className='bottom-cont FlexBox spaceBetween'>
                                        <PagesMenu
                                            activeSection={activeSection}
                                            homeOffset={homeSecRef.current?.offsetLeft}
                                            aboutOffset={aboutSecRef.current?.offsetLeft}
                                            shopOffset={shopSecRef.current?.offsetLeft}
                                            blogOffset={blogSecRef.current?.offsetLeft}
                                            footerOffset={footerSecRef.current?.offsetLeft}
                                        />
                                        <Text tag='p' className={`h8 geoRegular lightWhite`} text={`© ${new Date().getFullYear()}`} />
                                    </div>
                                </div>
                                <div className='sections FlexBox' data-scroll-container ref={containerRef}>
                                    <ShopSmallSection ref={shopSmallSecRef} />
                                    <HomeSection ref={homeSecRef} activeSection={activeSection} homeText={pageData.content.hero_text} />
                                    <AboutUsSection
                                        ref={aboutSecRef}
                                        activeSection={activeSection}
                                        aboutData={pageData.content.about}
                                        madeIn={pageData.content.made_in}
                                    />
                                    <ShopSection
                                        ref={shopSecRef}
                                        activeSection={activeSection}
                                        shopText={pageData.content.shop_text}
                                        products={pageData.products}
                                    />
                                    <BlogSection
                                        ref={blogSecRef}
                                        activeSection={activeSection}
                                        blogs={pageData.posts}
                                        blogText={pageData.content.blog_text}
                                    />
                                    <FooterSection ref={footerSecRef} activeSection={activeSection} data={globalData} />
                                </div>
                            </LocomotiveScrollProvider>
                        </Page>
                    </HomeStyle>
                ) : null
            }
        }, [winWidth, pageOffsetScrollLeft, shopSmallWidth, containerRef, pageData, sections, activeSection])

        return pageData && globalData ? storeHomeDependScreen : null
    }
)

export default withUIContext(withDataContext(Home, ['global', 'pages']), [
    'winWidth',
    'makeHeaderBlack',
    'makeHeaderWhite',
    'shopSmallWidth',
    'getPageOffsetScroll',
    'pageOffsetScrollLeft',
    'shopSmallSectionHeight',
])

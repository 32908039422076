import { useRef, forwardRef, useEffect, useState, useImperativeHandle } from 'react'
import { gsap } from 'gsap'
import { useLocomotiveScroll } from 'react-locomotive-scroll'

//* HOC's
import { withUIContext } from 'context'

//* Helpers
import { wordCurvedAnim, storeText, config } from 'helpers'

//* Components
import Text from 'components/common/Text'
import Button from 'components/common/Button'
import Image from 'components/common/Image'

//* Style
import HomeSectionStyle from './style'

const HomeSection = forwardRef((props, ref) => {
    const { scroll } = useLocomotiveScroll()

    const secRef = useRef()
    const text1Ref = useRef()
    const text2Ref = useRef()
    const text3Ref = useRef()
    const btnRef = useRef()
    const imgRef = useRef()

    const [active, setActive] = useState('')

    useImperativeHandle(ref, () => {
        return secRef.current
    })

    useEffect(() => {
        if (props.winWidth >= 1280) {
            wordCurvedAnim(text2Ref.current.childNodes)
            wordCurvedAnim(text3Ref.current.childNodes)
        }
        if (!props.preloader) {
            setActive('animPlay')
        }
    }, [props.preloader])

    useEffect(() => {
        if (props.winWidth >= 1280) {
            gsap.to(text2Ref.current, { x: Number(scroll?.scroll.instance.scroll.x / -3).toFixed(0) })
            gsap.to(text3Ref.current, { x: Number(scroll?.scroll.instance.scroll.x / -2).toFixed(0) })
            gsap.to(btnRef.current, { x: Number(scroll?.scroll.instance.scroll.x / -1.5).toFixed(0) })
            gsap.to(imgRef.current, { scale: 1 - Number(scroll?.scroll.instance.scroll.x / -10000).toFixed(3) })
        }
    }, [scroll?.scroll.instance.scroll.x, props.activeSection])

    return (
        <HomeSectionStyle ref={secRef} style={props.style} className='max-height-100' data-scroll-section>
            <Image ref={imgRef} className={`bg ${active}`} src='/images/home/homeSectionBg.png' alt='home-bg' />
            <Text ref={text1Ref} tag='p' className={`text h7 geoRegular white ${active}`} text={props.homeText} />
            <Text ref={text2Ref} className={`title h2 grandItalic white animLetterSpacing ${active}`}>
                {props.winWidth >= 1280 ? storeText('"le sens du beau"') : 'le sens du beau'}
            </Text>
            <Text ref={text3Ref} className={`subtitle h1 michroma white animLetterSpacing ${active}`}>
                {props.winWidth >= 1280 ? storeText('Bauwssen') : 'Bauwssen'}
            </Text>
            <Button link={config.routes.shop.path} ref={btnRef} noTransition type='homeBtn' text='visitOurShop' />
            {props.winWidth <= 1280 && <Text tag='p' className={`date h8 geoRegular lightWhite`} text={`© ${new Date().getFullYear()}`} />}
        </HomeSectionStyle>
    )
})

export default withUIContext(HomeSection, ['preloader', 'winWidth'])

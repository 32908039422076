import styled from 'styled-components'

const BlogSectionStyle = styled.div.attrs((props) => ({}))`
    padding:0 var(--containerPaddingHome);
	overflow: hidden;
	position: relative;
	min-width: max-content;

    
	.info {
		margin-top:var(--sp26x);
		margin-bottom: var(--sp27x);
		margin-right: var(--secToSecMarginHorizontal);
		justify-content: space-between;
		align-items: flex-start;
		
		&.animPlay {
			.top {
				.subtitle {
						transform: scaleX(1);
						opacity: 1;
					}
				}

                .title {
                    span:nth-child(2) {
                        letter-spacing: -0.45em;
                    }

                    span:nth-child(3) {
                        letter-spacing: -0.13em;
                    }
                }
				
				.btn {
				opacity: 1;
				transform: translateX(0)
			}
		}

		.top {
			.subtitle {
				max-width: var(--sp103x);
				margin-top: var(--sp5x);
				transition: 1s cubic-bezier(0.76, 0, 0.24, 1) 1s;
				transform: scaleX(1.6);
				opacity: 0;
			}
			
		}
		.btn {
			opacity: 0;
			transition: 0.8s cubic-bezier(0.76, 0, 0.24, 1) 1s;
			transform: translateX(50%);
		}
	}

	.blogs {
		width: 3700px !important;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		.info {
			margin-top:var(--sp19x);
			margin-bottom: var(--sp16x);
			
			.top {
				.subtitle {
					max-width: var(--sp78x);
					margin-top:var(--sp4x);
				}
			}
		}

		.blogs {
			width: 2760px !important;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		.info {
			margin-top: var(--sp19x);
			margin-bottom: var(--sp16x);

			.top {
				.subtitle {
					max-width: var(--sp78x);
					margin-top: var(--sp4x);
				}
			}
		}

		.blogs {
			width: 2160px !important;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}) {
		.info {
			margin-top: var(--sp16x);
			margin-bottom: var(--sp18x);

			.top {
				.subtitle {
					max-width: var(--sp78x);
					margin-top: var(--sp4x);
				}
			}
		}

		.blogs {
			width: 2160px !important;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		padding: 0 var(--sp8x);
		padding-bottom: var(--sp15x);
		overflow: hidden;
		position: relative;
		min-width: unset;
		flex-direction: column;
		
		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}){
			padding: 0 var(--sp4x);
			padding-bottom: var(--sp15x);
		}

		.info {
			margin-top: var(--sp15x);
			margin-bottom: var(--sp12x);

			.top {
				flex-direction: row;
				justify-content: space-between;
				width: 100%;

				.subtitle {
					max-width: var(--sp54x);

					@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}){
						max-width: var(--sp42x);
					}
					margin-top: 0;
				}
			}

			.homeBtn {
				margin-top:var(--sp7x);
			}
		}

		.blogs {
			width: 100% !important;
			flex-direction:column;
			margin-bottom: -24%;
			@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSize}){
				margin-bottom: -26%;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		padding: 0 var(--sp2x);
		padding-bottom: var(--sp8x);
		overflow: hidden;
		position: relative;
		min-width: unset;
		flex-direction: column;

		.info {
			margin-top: var(--sp12x);
			margin-bottom: var(--sp9x);

			.top {
				flex-direction: column;
				justify-content: space-between;
				width: 100%;

				.subtitle {
					max-width: unset;
					margin-top: var(--sp2x);
				}
			}

			.homeBtn {
				margin-top:var(--sp6x);
			}
		}

		.blogs {
			flex-direction:column;
			margin-bottom: -110%;
			width: 100% !important;
		}
	}
`

export default BlogSectionStyle

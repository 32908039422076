import styled from 'styled-components'

const HomeStyle = styled.div`
	.home-hdr {
		position: fixed;
		left: var(--sp21x);
		bottom: 0;
		width: calc(100vw - var(--sp21x));
		height: 100vh;
		z-index: 2;
		padding: 0 var(--containerPaddingHome) var(--sp8x);
		pointer-events: none;
		mix-blend-mode: difference;
		justify-content: flex-end;

		.pages {
			pointer-events: all;

			.comp-title {
				margin-right: var(--sp7x);
			}
		}

		.cart-account {
			align-self: flex-end;
			align-items: center;
			pointer-events: all;
			margin-top: var(--sp8x);

			i {
				font-size: var(--sp6x);
				margin: 0 var(--sp2x) 0 var(--sp4x);
				:before {
					color: var(--lightWhite);
				}
			}
		}
	}

	.sections {
		overflow: hidden;
		position: relative;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		.home-hdr {
			left: var(--sp15x);
			width: calc(100vw - var(--sp15x));
			padding: 0 var(--containerPaddingHome) var(--sp6x);

			.cart-account {
				margin-top: var(--sp6x);

				i {
					font-size: var(--sp4-5x);
					margin: 0 var(--sp1-5x) 0 var(--sp3x);
				}
			}

			.pages {
				.comp-title {
					margin-right: var(--sp6x);
				}
			}
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		.home-hdr {
			left: var(--sp12x);
			width: calc(100vw - var(--sp12x));
			padding: 0 var(--containerPaddingHome) var(--sp5x);

			.pages {
				.comp-title {
					margin-right: var(--sp4x);
				}
			}

			.cart-account {
				margin-top: var(--sp6x);

				i {
					font-size: var(--sp3-5x);
					margin: 0 var(--sp1-5x) 0 var(--sp3x);
				}
			}
		}
	}
`

export default HomeStyle

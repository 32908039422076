import styled from 'styled-components'

const FooterSectionStyle = styled.div.attrs((props) => ({}))`
	padding: 0 var(--containerPaddingHome);
	background-color: var(--black);
	overflow-y: hidden;
	position: relative;
	min-width: max-content;
	opacity: 1 !important;
	pointer-events: all !important;

	.soc {
		&:hover {
			color: var(--darkGrey);
		}
	}

	.customUnderline {
		position: relative;

		&:before {
			content: '';
			transition: 800ms;
			display: inline-block;
			background: #fff;
			height: 3px;
			width: 0%;
			position: absolute;
			top: 100%;
			left: 0;
		}

		&:hover {
			&:before {
				width: 100%;
			}
		}
	}

	.footer {
		margin-top: var(--sp26x);
		margin-right: var(--secToSecMarginHorizontal);
		width: 100%;

		.animText {
			transition: 0.5s cubic-bezier(0.76, 0, 0.24, 1);
			transform: translateX(30%) scaleX(1.6);
			opacity: 0;

			&.animPlay {
				opacity: 1;
				transform: scaleX(1) translateX(0);
			}
		}

		.top {
			.single-info {
				max-width: var(--sp73x);

				&:nth-child(2) {
					margin: 0 var(--secToSecMarginHorizontal);
				}

				.title {
					margin-bottom: var(--sp4x);
				}
			}
		}

		.bottom {
			margin-top: var(--sp19x);

			.title {
				margin-bottom: var(--sp4x);
			}

			.socials {
				margin: var(--sp4x) 0 var(--sp10x);

				a {
					cursor: pointer;
					position: relative;

					&:nth-child(2) {
						margin: 0 var(--sp10x);
					}

					&:not(:last-child) {
						&:before {
							content: '';
							position: absolute;
							top: 50%;
							left: calc(100% + var(--sp4x));
							transform: translateY(-50%);
							width: 14px;
							height: 14px;
							border-radius: 50%;
							background-color: var(--white);
						}
					}
				}
			}

			.ftr {
				.conceptReference {
					margin-right: 8%;
				}
				.concept {
					margin-left: var(--sp2x);
				}
			}
		}
	}

	.pic-1 {
		min-width: 40.6vw;
		padding-top: 100vh;
	}

	.pic-2 {
		align-self: flex-end;
		min-width: 23vw;
		padding-top: 28.7vw;
		margin-left: var(--sp7x);
		margin-right: var(--containerPaddingHome);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		.footer {
			margin-top: var(--sp20x);

			.top {
				.single-info {
					max-width: var(--sp66x);

					.title {
						margin-bottom: var(--sp3x);
					}
				}
			}

			.bottom {
				margin-top: var(--sp15x);

				.title {
					margin-bottom: var(--sp3x);
				}

				.socials {
					margin: var(--sp3x) 0 var(--sp9x);

					a {
						&:nth-child(2) {
							margin: 0 var(--sp7x);
						}

						&:not(:last-child) {
							&:before {
								left: calc(100% + var(--sp3x));
								width: 8px;
								height: 8px;
							}
						}
					}
				}
			}
		}

		.pic-2 {
			margin-left: var(--sp5x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		.footer {
			margin-top: var(--sp15x);

			.top {
				.single-info {
					max-width: var(--sp59x);

					.title {
						margin-bottom: var(--sp2-5x);
					}
				}
			}

			.bottom {
				margin-top: var(--sp10x);

				.title {
					margin-bottom: var(--sp2-5x);
				}

				.socials {
					margin: var(--sp2-5x) 0 var(--sp8x);

					a {
						&:nth-child(2) {
							margin: 0 var(--sp7x);
						}

						&:not(:last-child) {
							&:before {
								left: calc(100% + var(--sp3x));
								width: 8px;
								height: 8px;
							}
						}
					}
				}
			}
		}

		.pic-2 {
			margin-left: var(--sp4x);
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		.footer {
			margin-top: var(--sp13x);

			.top {
				.single-info {
					max-width: var(--sp59x);

					.title {
						margin-bottom: var(--sp2-5x);
					}
				}
			}

			.bottom {
				margin-top: var(--sp10x);

				.title {
					margin-bottom: var(--sp2-5x);
				}

				.socials {
					margin: var(--sp2-5x) 0 var(--sp5x);

					a {
						&:nth-child(2) {
							margin: 0 var(--sp7x);
						}

						&:not(:last-child) {
							&:before {
								left: calc(100% + var(--sp3x));
								width: 8px;
								height: 8px;
							}
						}
					}
				}
			}
		}

		.pic-2 {
			margin-left: var(--sp4x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		padding: 0 var(--sp8x);
		margin: var(--sp12x) 0 var(--sp15x);
		min-width: unset;
		flex-wrap: wrap;
		flex-direction: row;

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
			padding: 0 var(--sp4x);
		}

		.footer {
			margin-top: 0;
			margin-bottom: var(--sp15x);
			.top {
				flex-wrap: wrap;
				justify-content: space-between;

				.single-info {
					max-width: var(--sp59x);

					@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
						&:nth-child(1),
						&:nth-child(3) {
							width: 50%;
						}
					}

					&:nth-child(1) {
						order: 1;
					}

					&:nth-child(2) {
						order: 3;
						margin-top: var(--sp6x);
					}

					&:nth-child(3) {
						order: 2;
					}

					.title {
						margin-bottom: var(--sp2-5x);
					}
				}
			}

			.bottom {
				margin-top: var(--sp10x);

				.title {
					margin-bottom: var(--sp2-5x);
				}

				.socials {
					margin: var(--sp2-5x) 0 var(--sp5x);

					a {
						&:nth-child(2) {
							margin: 0 var(--sp7x);
						}

						&:not(:last-child) {
							&:before {
								left: calc(100% + var(--sp3x));
								width: 8px;
								height: 8px;
							}
						}
					}
				}
			}
		}

		.pic-1 {
			width: 61.4%;
			padding-top: 76.8%;
			min-width: unset;
		}

		.pic-2 {
			margin-left: var(--sp2-5x);
			width: 36.2%;
			padding-top: 44.7%;
			margin-right: 0;
		}

		.customUnderline {
			&:hover {
				&:before {
					width: 0%;
				}
			}
		}

		.soc {
			&:hover {
				color: #fff;
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
			.pic-2 {
				width: 35.5%;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		padding: 0 var(--sp2x);
		margin: var(--sp12x) 0;
		min-width: unset;

		flex-direction: column;

		.footer {
			margin-top: 0;

			.top {
				flex-direction: column;

				.single-info {
					max-width: unset;

					&:nth-child(1) {
						order: 1;
					}

					&:nth-child(2) {
						order: 3;
						margin-top: var(--sp8x);
					}

					&:nth-child(3) {
						order: 2;
						margin-top: var(--sp8x);
					}

					.title {
						margin-bottom: var(--sp2-5x);
					}
				}
			}

			.bottom {
				margin-top: var(--sp9x);

				.title {
					margin-bottom: var(--sp2-5x);
				}

				.socials {
					margin: var(--sp2-5x) 0 var(--sp7x);

					a {
						&:nth-child(2) {
							margin: 0 var(--sp7x);
						}

						&:not(:last-child) {
							&:before {
								left: calc(100% + var(--sp3x));
								width: 8px;
								height: 8px;
							}
						}
					}
				}

				.ftr {
					flex-direction: column;

					& > a {
						margin-bottom: var(--sp3x);
					}
				}
			}
		}

		.pic-1 {
			width: 100%;
			padding-top: 125.5%;
			min-width: unset;
			margin-top: var(--sp12x);
		}

		.pic-2 {
			margin-top: var(--sp2-5x);
			margin-left: 0;
			width: 58.1%;
			padding-top: 72.7%;
			margin-right: 0;
			align-self: flex-start;
		}
		.customUnderline {
			&:hover {
				&:before {
					width: 0%;
				}
			}
		}
		.soc {
			&:hover {
				color: #fff;
			}
		}
	}
`

export default FooterSectionStyle

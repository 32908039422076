import { useRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'

//* HOCs
import { withUIContext } from 'context'

//* Components
import Text from 'components/common/Text'
import Button from 'components/common/Button'
import Image from 'components/common/Image'
import { wordCurvedAnim, storeText, config } from 'helpers'

//* Style
import ShopSmallSectionStyle from './style'

const ShopSmallSection = forwardRef((props, ref) => {
    const secRef = useRef()
    const text1Ref = useRef()
    const text2Ref = useRef()
    const text3Ref = useRef()
    const [rendered, setRendered] = useState('')

    useImperativeHandle(ref, () => {
        return secRef
    })

    useEffect(() => {
        props.setShopSmallWidth(ref.current.current?.clientWidth)
    }, [secRef.current?.clientWidth])

    useEffect(() => {
        wordCurvedAnim(text1Ref.current.childNodes)
        wordCurvedAnim(text2Ref.current.childNodes)
        wordCurvedAnim(text3Ref.current.childNodes)
    }, [])

    useEffect(() => {
        props.getShopSmallHeight(secRef.current.clientHeight)

        if (!props.preloader) {
            setRendered('animPlay')
        }
    }, [props.preloader])

    return (
        <ShopSmallSectionStyle
            ref={secRef}
            style={props.style}
            className={` FlexBoxColumn ${rendered}`}
            contHeight={`${props.winWidth >= 1280 ? `${props.winHeight}px` : 'auto'}`}
            data-scroll-section
        >
            <Text ref={text1Ref} className={`h3 michroma black animLetterSpacing mainTitle ${rendered}`}>
                {storeText('Visitez')}
            </Text>
            <Text ref={text2Ref} className={`second h3 grandItalic black animLetterSpacing ${rendered}`}>
                <svg xmlns='http://www.w3.org/2000/svg' width='669.67' height='233.373' viewBox='0 0 669.67 233.373'>
                    <path
                        id='Path_54673'
                        data-name='Path 54673'
                        d='M806.214,2207.627c-89.508-10.391-296.088,2.1-368.854,25.135s-96.606,58.125-87.823,85.356,72.123,78.881,331.215,59.589c0,0,286.4-17.807,289.481-121.688S616.3,2176.626,616.3,2176.626s-225.934,16.324-236.73,89.04,95.675,112.042,95.675,112.042,234.043,94.685,541.391-59.589'
                        transform='translate(-347.28 -2173.595)'
                        fill='none'
                        stroke='#201f1f'
                        strokeMiterlimit='10'
                        strokeWidth='1'
                    />
                </svg>
                {storeText('Bauwssen')}
            </Text>
            <Text ref={text3Ref} className={`h3 michroma black animLetterSpacing ${rendered}`}>
                {storeText('boutique')}
            </Text>
            <Image priority={true} loading='eager' className={rendered} src='/images/home/tableBtq.png' />
            <Button link={config.routes.shop.path} type='homeBtn' classNameParent='black' text='visitOurShop' />
        </ShopSmallSectionStyle>
    )
})

export default withUIContext(ShopSmallSection, ['setShopSmallWidth', 'preloader', 'getShopSmallHeight', 'winWidth', 'winHeight'])

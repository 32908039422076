import { useRef, useMemo, forwardRef, useEffect, useState, useImperativeHandle } from 'react'
import { useLocomotiveScroll } from 'react-locomotive-scroll'

//* HOC's
import { withLanguageContext, withUIContext } from 'context'

//* Helpers
import { wordCurvedAnim, storeText, config } from 'helpers'

//* Components
import Text from 'components/common/Text'
import Button from 'components/common/Button'
import SingleProductHome from 'components/common/SingleProductHome'

//* Style
import ShopSectionStyle from './style'

const ShopSection = forwardRef((props, ref) => {
	const { scroll } = useLocomotiveScroll()

	//! States
	const secRef = useRef()
	const titleRef = useRef()
	const prodRefs = useRef([])
	const [blockActive, setBlockActive] = useState('')

	useImperativeHandle(ref, () => {
		return secRef.current
	})

	useEffect(() => {
		if (props.winWidth >= 1280) {
			wordCurvedAnim(titleRef.current.childNodes)
		}
	}, [props.preloader, props.winWidth])

	useEffect(() => {
		if (props.activeSection > 1 && props.activeSection < 4 && props.winWidth >= 1280) {
			if (scroll?.scroll.instance.scroll.x > secRef.current.offsetLeft - props.winWidth * 0.75) {
				setBlockActive('animPlay')
			} else {
				setBlockActive('')
			}

			if (props.activeSection >= 2) {
				for (let i = 0; i < prodRefs.current.length; i++) {
					if (scroll?.scroll.instance.scroll.x > secRef.current.offsetLeft + prodRefs.current[i].offsetLeft - props.winWidth * 0.8) {
						prodRefs.current[i].classList.add('active')
					} else {
						prodRefs.current[i].classList.remove('active')
					}
				}
			}
		}
	}, [scroll?.scroll.instance.scroll.x, props.activeSection, props.winWidth])

	const storeProducts = useMemo(() => {
		return props.products.map((i, k) => {
			return (
				<SingleProductHome
					ref={(refs) => (props.products.length > prodRefs.current.length ? prodRefs.current.push(refs) : prodRefs.current)}
					key={k}
					category={i.category}
					title={i.name}
					images={i.featured_images}
					price={i.price}
					slug={i.slug}
					id={i.id}
				/>
			)
		})
	}, [props.products])

	return (
		<ShopSectionStyle ref={secRef} style={props.style} className='max-height-100 FlexBox' data-scroll-section>
			<div className={`info FlexBoxColumn ${props.winWidth >= 1280 ? blockActive : 'animPlay'}`}>
				<div className='top FlexBoxColumn'>
					<Text ref={titleRef} className={`title h2 grandItalic animLetterSpacing ${props.winWidth >= 1280 ? blockActive : 'animPlay'}`}>
						{props.winWidth >= 1280 ? storeText(props.translate('shop')) : props.translate('shop')}
					</Text>
					<Text tag='p' className={`subtitle h7 geoRegular`} text={props.shopText} />
				</div>
				<Button link={config.routes.shop.path} type='homeBtn' classNameParent={`black`} text='goToShop' />
			</div>
			<div className='shop FlexBox alignMiddle'>{storeProducts}</div>
		</ShopSectionStyle>
	)
})

export default withLanguageContext(withUIContext(ShopSection, ['preloader', 'winWidth']), ['translate'])

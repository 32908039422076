import React, { useEffect } from 'react'
import { useLocomotiveScroll } from 'react-locomotive-scroll'

//* HOC's
import { withDataContext } from 'context'

//* Components
import Text from 'components/common/Text'

const PagesMenu = (props) => {
    const { scroll } = useLocomotiveScroll()

    const clickHandler = (scrollPos) => {
        scroll?.scrollTo(scrollPos, { offset: -85 })
    }

    useEffect(() => {
        if (props.isLogoClicked) {
            scroll?.scrollTo(0, { offset: -100 })
            props.logoClickScroll()
        }
    }, [props.isLogoClicked])

    return (
        <div className='pages FlexBox'>
            <Text
                onClick={() => clickHandler(props.homeOffset)}
                tag='p'
                className={`h8 cursorPointer  ${props.activeSection === 1 ? 'geoSemiBold white' : 'geoRegular darkGrey'}`}
                text='home'
            />
            <Text
                onClick={() => clickHandler(props.aboutOffset)}
                tag='p'
                className={`h8 cursorPointer  ${props.activeSection === 2 ? 'geoSemiBold white' : 'geoRegular darkGrey'}`}
                text='aboutUs'
            />
            <Text
                onClick={() => clickHandler(props.shopOffset)}
                tag='p'
                className={`h8 cursorPointer ${props.activeSection === 3 ? 'geoSemiBold white' : 'geoRegular darkGrey'}`}
                text='shop'
            />
            <Text
                onClick={() => clickHandler(props.blogOffset)}
                tag='p'
                className={`h8 cursorPointer ${props.activeSection === 4 ? 'geoSemiBold white' : 'geoRegular darkGrey'}`}
                text='blog'
            />
            <Text
                onClick={() => clickHandler(props.footerOffset)}
                tag='p'
                className={`h8 cursorPointer ${props.activeSection === 5 ? 'geoSemiBold white' : 'geoRegular darkGrey'}`}
                text='contactUs'
            />
        </div>
    )
}

export default withDataContext(PagesMenu, ['isLogoClicked', 'logoClickScroll'])

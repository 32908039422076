import { useRef, useMemo, forwardRef, useEffect, useState, useImperativeHandle } from 'react'
import { useLocomotiveScroll } from 'react-locomotive-scroll'

//* HOC's
import { withUIContext } from 'context'

//* Helpers
import { wordCurvedAnim, storeText, config } from 'helpers'

//* Components
import Text from 'components/common/Text'
import Button from 'components/common/Button'
import SingleBlogHome from 'components/common/SingleBlogHome'

//* Style
import BlogSectionStyle from './style'

const BlogSection = forwardRef((props, ref) => {
    const { scroll } = useLocomotiveScroll()

    //! Refs
    const secRef = useRef()
    const titleRef = useRef()
    const blogsRef = useRef()
    const blogRefs = useRef([])

    //! States
    const [blockActive, setBlockActive] = useState('')
    const [lastActive, setLastActive] = useState(0)
    const [blogInitialWidth, setBlogInitialWidth] = useState(0)

    useImperativeHandle(ref, () => {
        return secRef.current
    })

    useEffect(() => {
        setBlogInitialWidth(blogsRef?.current?.clientWidth)
    }, [])

    useEffect(() => {
        if (props.winWidth >= 1280) {
            wordCurvedAnim(titleRef.current.childNodes)
        }
    }, [props.preloader])

    useEffect(() => {
        if (props.activeSection > 2 && props.activeSection < 5 && props.winWidth >= 1280) {
            if (scroll?.scroll.instance.scroll.x > secRef.current.offsetLeft - props.winWidth * 0.75) {
                setBlockActive('animPlay')
            } else {
                setBlockActive('')
            }

            if (props.activeSection >= 3) {
                for (let i = 0; i < blogRefs.current.length; i++) {
                    let wdtMinus = i === 2 ? blogRefs.current[i].clientWidth / 2 : i === 3 ? blogRefs.current[i].clientWidth : 0

                    if (
                        scroll?.scroll.instance.scroll.x >
                        secRef.current.offsetLeft + blogRefs.current[i].offsetLeft - props.winWidth * 0.85 - wdtMinus
                    ) {
                        if (!blogRefs.current[i].classList.contains('active')) {
                            blogRefs.current[i].classList.add('active')
                            setLastActive(i)
                        }
                    } else {
                        blogRefs.current[i].classList.remove('active')
                        setLastActive(i)
                    }
                }
            }
        }
    }, [scroll?.scroll.instance.scroll.x, props.activeSection, props.winWidth])

    const storeBlogs = useMemo(() => {
        return props.blogs.map((i, k) => {
            return (
                <SingleBlogHome
                    ref={(refs) => (props.blogs.length > blogRefs.current.length ? blogRefs.current.push(refs) : blogRefs.current)}
                    key={k}
                    slug={`/blog/${i.slug}`}
                    readingTime={i.reading_time}
                    title={i.title}
                    date={i.date}
                    description={i.short_description}
                    photo={i.featured_image}
                />
            )
        })
    }, [props.blogs])

    return (
        <BlogSectionStyle ref={secRef} style={props.style} className='max-height-100 FlexBox' data-scroll-section>
            <div className={`info FlexBoxColumn ${props.winWidth >= 1280 ? blockActive : 'animPlay'}`}>
                <div className='top FlexBoxColumn'>
                    <Text
                        ref={titleRef}
                        className={`title h2 grandItalic animLetterSpacing white ${props.winWidth >= 1280 ? blockActive : 'animPlay'}`}
                    >
                        {storeText('Blog')}
                    </Text>
                    <Text tag='p' className='subtitle h7 geoRegular white' text={props.blogText} />
                </div>
                <Button link={config.routes.blog.path} type='homeBtn' text='toBlog' />
            </div>
            <div ref={blogsRef} className='blogs FlexBox alignMiddle'>
                {storeBlogs}
            </div>
        </BlogSectionStyle>
    )
})

export default withUIContext(BlogSection, ['preloader', 'winWidth'])

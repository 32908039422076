import { forwardRef, useRef, useImperativeHandle, useEffect, useState } from 'react'
import { useLocomotiveScroll } from 'react-locomotive-scroll'
import { wordCurvedAnim, storeText } from 'helpers'
import { gsap } from 'gsap'

//* HOC's
import { withUIContext } from 'context'

//* Components
import Text from 'components/common/Text'
import Image from 'components/common/Image'

//* Style
import AboutUsSectionStyle from './style'

const AboutUsSection = forwardRef((props, ref) => {
    const { scroll } = useLocomotiveScroll()

    const secRef = useRef()
    const block1Ref = useRef()
    const text1Ref = useRef()
    const text2Ref = useRef()
    const text3Ref = useRef()
    const imgRef1 = useRef()
    const imgRef2 = useRef()
    const imgRef3 = useRef()
    const infoRef = useRef()
    const infoRef2 = useRef()
    const abText1 = useRef()
    const abText2 = useRef()
    const abText3 = useRef()

    const [active1Block, setActiveStates] = useState({
        title: '',
        text: '',
        subTitle: '',
    })

    const [active2Block, setActive2Block] = useState({
        img1: '',
        img2: '',
        infoState: '',
    })

    const [active3Block, setActive3Block] = useState({
        img: '',
        infoState2: '',
    })

    useImperativeHandle(ref, () => {
        return secRef.current
    })

    useEffect(() => {
        if (props.winWidth >= 1280) {
            wordCurvedAnim(text1Ref.current.childNodes)
            wordCurvedAnim(text2Ref.current.childNodes)
            wordCurvedAnim(text3Ref.current.childNodes)
        }
    }, [props.preloader, props.winWidth])

    useEffect(() => {
        if (props.activeSection === 1 || props.activeSection === 2) {
            if (secRef.current.offsetLeft - scroll?.scroll?.instance.scroll.x < 1500) {
                setActiveStates({ ...active1Block, title: 'animPlay', text: 'animPlay' })
            } else {
                setActiveStates({ title: '', text: '', subTitle: '' })
            }

            if (secRef.current.offsetLeft < scroll?.scroll.instance.scroll.x) {
                setActiveStates({ ...active1Block, subTitle: 'animPlay' })
                gsap.to(text3Ref.current, { x: scroll?.scroll.instance.scroll.x / -10 })
            }

            if (
                scroll?.scroll?.instance.scroll.x >
                imgRef1.current[0].offsetLeft + imgRef1.current[0].clientWidth / 4 + block1Ref.current.clientWidth
            ) {
                setActive2Block({ ...active2Block, img1: 'active' })

                if (
                    scroll?.scroll?.instance.scroll.x >
                    imgRef2.current[0].offsetLeft + imgRef2.current[0].clientWidth / 4 + block1Ref.current.clientWidth
                ) {
                    setActive2Block({ ...active2Block, img2: 'active' })
                }
            }
            //info texts

            if (props.activeSection === 2) {
                if (scroll?.scroll?.instance.scroll.x > infoRef.current.offsetLeft + infoRef.current.clientWidth + props.winWidth / 2) {
                    setActive2Block({ ...active2Block, infoState: 'active' })
                    if (active2Block.infoState === 'active') {
                        gsap.to(abText1.current, 0, { transition: 0, scaleX: 1, x: scroll.scroll.instance.scroll.x / -30 })
                    }
                }

                if (
                    scroll?.scroll?.instance.scroll.x >
                    imgRef3.current[0].offsetLeft + imgRef3.current[0].clientWidth / 4 + block1Ref.current.clientWidth
                ) {
                    setActive3Block({ ...active3Block, img: 'active' })
                }

                if (scroll?.scroll?.instance.scroll.x > infoRef2.current.offsetLeft + infoRef2.current.clientWidth + props.winWidth / 2) {
                    setActive3Block({ ...active3Block, infoState2: 'active' })
                }
            }
        }
    }, [scroll?.scroll?.instance.scroll.x, props.activeSection])

    return (
        <AboutUsSectionStyle ref={secRef} style={props.style} className='max-height-100 FlexBox' data-scroll-section>
            <div ref={block1Ref} className='ab-section-1 FlexBoxColumn'>
                <Text ref={text1Ref} className={`title h1 michroma white animLetterSpacing ${active1Block.title}`}>
                    {props.winWidth >= 1280 ? storeText('Bauwssen') : 'Bauwssen'}
                </Text>
                <Text ref={text2Ref} className={`uppercase subtitle h5 michroma white animLetterSpacing ${active1Block.text}`}>
                    {props.winWidth >= 1280 ? storeText(props.madeIn) : props.madeIn}
                </Text>
                <Text
                    ref={text3Ref}
                    tag='p'
                    className={`text h1 grandItalic white animLetterSpacing ${props.winWidth >= 1280 ? active1Block.subTitle : 'animPlay'}`}
                >
                    <svg xmlns='http://www.w3.org/2000/svg' width='1252.377' height='452.606' viewBox='0 0 1252.377 452.606'>
                        <path
                            id='Path_54673'
                            data-name='Path 54673'
                            d='M1157.324,2245.011c-158.064-21.982-522.869,4.434-651.368,53.173s-170.6,122.963-155.089,180.569,127.364,166.871,584.9,126.059c0,0,505.756-37.671,511.2-257.426s-625.029-167.956-625.029-167.956S422.959,2213.963,403.9,2367.791s303.663,237.021,303.663,237.021,555,113.219,891.765-188.1'
                            transform='translate(-347.28 -2173.595)'
                            fill='none'
                            stroke='#efefef'
                            strokeMiterlimit='10'
                            strokeWidth='1'
                        />
                    </svg>
                    {props.winWidth >= 1280 ? storeText('Design') : 'Design'}
                </Text>
            </div>
            <div className='ab-section-2 FlexBox'>
                <Image
                    ref={imgRef1}
                    className={`pic-1 ${props.winWidth >= 1280 ? active2Block.img1 : 'active'}`}
                    src='/images/home/Bauwssen_blog_individual.jpg'
                    alt='home-bg'
                />
                <Image
                    ref={imgRef2}
                    className={`pic-2 ${props.winWidth >= 1280 ? active2Block.img2 : 'active'}`}
                    src='/images/home/Bauwssen_Homepage.jpg'
                    alt='home-bg'
                />
                <div ref={infoRef} className={`info FlexBoxColumn ${props.winWidth >= 1280 ? active2Block.infoState : 'active'}`}>
                    <Text ref={abText1} tag='h5' className='title h5 michroma white aboutTitle1' text={props.aboutData.title_1} />
                    <div className='bottom FlexBox'>
                        <Text ref={abText2} tag='p' className='text-1 h7 geoRegular white' text={props.aboutData.text_1} />
                        <Text ref={abText3} tag='p' className='text-2 h7 geoRegular white' text={props.aboutData.text_2} />
                    </div>
                </div>
            </div>
            <div className='ab-section-3 FlexBox'>
                <Image
                    ref={imgRef3}
                    className={`pic-1 ${props.winWidth >= 1280 ? active3Block.img : 'active'}`}
                    src='/images/home/twoChair.jpg'
                    alt='home-bg'
                />
                <div ref={infoRef2} className={`info FlexBoxColumn ${props.winWidth >= 1280 ? active3Block.infoState2 : 'active'}`}>
                    <Text tag='h5' className='title h5 michroma white' text={props.aboutData.title_2} />
                    <Text tag='p' className='text h7 geoRegular white' text={props.aboutData.text_3} />
                </div>
            </div>
        </AboutUsSectionStyle>
    )
})

export default withUIContext(AboutUsSection, ['preloader', 'winWidth'])

import styled from 'styled-components'

const HomeSectionStyle = styled.div.attrs((props) => ({}))`
	background-color: var(--black);
	overflow: hidden;
	margin-left: var(--sp21x);
	padding-right: var(--sp40x);
	position: relative;
	min-width: 153.4vw;
	display: inline-block;

	.bg {
		width: 100%;
		padding-top: 100vh;
		transform-origin: right;

		& > div {
			transform: scale(1.16);
		}

		&.animPlay {
			& > div {
				transition: 2s cubic-bezier(0.76, 0, 0.24, 1);
				transform: scale(1);
			}
		}
	}

	& > .comp-title {
		position: absolute;
	}

	.text {
		top: var(--sp8x);
		left: var(--containerPaddingHome);
		max-width: var(--sp92x);
		z-index: 1;
		transition: 0.5s cubic-bezier(0.76, 0, 0.24, 1);
		transform: scaleX(1.6);

		&.animPlay {
			transform: scaleX(1);
		}
	}

	.title {
		top: var(--sp50x);
		left: 22%;
		z-index: 1;
	}

	.subtitle {
		top: var(--sp75x);
		left: 31%;
		z-index: 1;
	}

	.btn {
		position: absolute;
		top: var(--sp135x);
		left: 62%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		margin-left: var(--sp15x);
		padding-right: var(--sp28x);
		min-width: 137.4vw;

		.text {
			top: var(--sp6x);
			max-width: var(--sp68x);
		}

		.title {
			top: var(--sp33x);
			left: 25%;
		}

		.subtitle {
			top: var(--sp53x);
		}

		.btn {
			top: var(--sp91x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		margin-left: var(--sp12x);
		padding-right: var(--sp21x);
		min-width: 153.6vw;

		.text {
			top: var(--sp5x);
			max-width: var(--sp58x);
		}

		.title {
			top: var(--sp29x);
			left: 25%;
		}

		.subtitle {
			top: var(--sp45x);
		}

		.btn {
			top: var(--sp75x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		margin-left: var(--sp12x);
		padding-right: var(--sp21x);
		min-width: 153.6vw;

		.text {
			top: var(--sp5x);
			max-width: var(--sp58x);
		}

		.title {
			top: var(--sp22x);
			left: 25%;
		}

		.subtitle {
			top: var(--sp37x);
		}

		.btn {
			top: var(--sp65x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		min-width: unset;
		overflow: hidden;
		margin-left: 0;
		padding-right: 0;
		position: relative;
		margin-top: 76px;

		.bg {
			width: 100%;
			padding-top: 79vh;
			margin-top: var(--sp12x);
			transform: scale(1) !important;

			& > div {
				transform: scale(1);
			}
		}

		.text {
			top: var(--sp4x);
			max-width: var(--sp55x);
		}

		.title {
			top: var(--sp33x);
			left: var(--sp8x);
		}

		.subtitle {
			top: var(--sp43x);
			left: unset;
			right: var(--sp8x);
		}

		.btn {
			top: var(--sp64x);
			left: unset;
			right: var(--sp8x);
		}

		.date {
			z-index: 1;
			position: relative;
			margin: var(--sp3x) var(--sp8x);
			text-align: right;
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
			flex-direction: column;

			.bg {
				width: 167%;
				padding-top: 58vh;
				margin-top: var(--sp48x);
				margin-left: -120px;
				transform: scale(1) !important;
				align-self: flex-end;

				& > div {
					transform: scale(1);
				}
			}

			.text {
				top: var(--sp4x);
				max-width: var(--sp55x);
				left: var(--sp4x);
			}

			.title {
				top: var(--sp33x);
				left: var(--sp4x);
			}

			.subtitle {
				top: var(--sp43x);
				right: var(--sp4x);
			}

			.btn {
				top: var(--sp64x);
				right: var(--sp4x);
			}

			.date {
				margin: var(--sp3x) var(--sp4x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		flex-direction: column;
		min-width: unset;
		overflow: hidden;
		margin-left: 0;
		padding-right: 0;
		position: relative;

		.bg {
			width: 100%;
			padding-top: 58vh;
			margin-left: 0;
			margin-top: var(--sp30x);
			transform: scale(1) !important;

			& > div {
				transform: scale(1);
			}
		}

		.text {
			top: var(--sp3x);
			max-width: unset;
			left: var(--sp2x);
			width: 92%;
		}

		.title {
			top: var(--sp33x);
			left: var(--sp2x);
		}

		.subtitle {
			top: var(--sp43x);
			left: unset;
			right: var(--sp2x);
		}

		.btn {
			top: var(--sp56x);
			left: unset;
			right: var(--sp2x);
		}

		.date {
			z-index: 1;
			position: relative;
			margin: var(--sp3x) var(--sp2x);
			text-align: right;
		}
	}
`

export default HomeSectionStyle

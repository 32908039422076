import { forwardRef, useRef, useImperativeHandle, useEffect, useState } from 'react'
import { useLocomotiveScroll } from 'react-locomotive-scroll'

//* HOC's
import { withUIContext } from 'context'

//* Helpers
import { config } from 'helpers'

//* Components
import Text from 'components/common/Text'
import Button from 'components/common/Button'
import Image from 'components/common/Image'
import CustomLink from 'components/common/CustomLink'
import Translate from 'components/common/Translate'

//* Style
import FooterSectionStyle from './style'

const FooterSection = forwardRef((props, ref) => {
    const { scroll } = useLocomotiveScroll()

    //! Refs
    const secRef = useRef()
    const text1Ref = useRef()
    const text2Ref = useRef()

    //! States
    const [topBlocks, setTopBlocks] = useState({
        first: '',
        second: '',
    })

    useImperativeHandle(ref, () => {
        return secRef.current
    })

    useEffect(() => {
        if (props.activeSection >= 4 && props.winWidth >= 1280) {
            if (text1Ref.current.offsetLeft + secRef.current.offsetLeft - props.winWidth * 0.8 < scroll?.scroll.instance.scroll.x) {
                setTopBlocks({ ...topBlocks, first: 'animPlay' })

                if (text2Ref.current.offsetLeft + secRef.current.offsetLeft - props.winWidth * 0.8 < scroll?.scroll.instance.scroll.x) {
                    setTopBlocks({ ...topBlocks, second: 'animPlay' })
                }
            } else {
                setTopBlocks({ first: '', second: '' })
            }
        }
    }, [scroll?.scroll.instance.scroll.x, props.activeSection])

    return (
        <FooterSectionStyle ref={secRef} style={props.style} className='max-height-100 FlexBox' data-scroll-section>
            <div className='footer FlexBoxColumn'>
                <div className='top FlexBox'>
                    <div className='single-info FlexBoxColumn'>
                        <Text
                            ref={text1Ref}
                            tag='p'
                            className={`animText title h7 geoRegular white ${props.winWidth >= 1280 ? topBlocks.first : 'animPlay'}`}
                            text='addressEmail'
                        />
                        <a
                            className={`animText h7 geoRegular white ${props.winWidth >= 1280 ? topBlocks.first : 'animPlay'}`}
                            href={`mailto:${props.data.email}`}
                        >
                            {props.data.email}
                        </a>
                    </div>
                    <div className='single-info FlexBoxColumn'>
                        <Text
                            tag='p'
                            className={`animText title h7 geoRegular white ${props.winWidth >= 1280 ? topBlocks.second : 'animPlay'}`}
                            text='phoneNumberFooter'
                        />
                        <a
                            className={`animText title h7 geoRegular white ${props.winWidth >= 1280 ? topBlocks.second : 'animPlay'}`}
                            href={`tel:${props.data.phone[0]}`}
                        >
                            {props.data.phone[0]}
                        </a>
                        <a
                            className={`animText title h7 geoRegular white ${props.winWidth >= 1280 ? topBlocks.second : 'animPlay'}`}
                            href={`tel:${props.data.phone[1]}`}
                        >
                            {props.data.phone[1]}
                        </a>
                    </div>
                    <div className='single-info FlexBoxColumn'>
                        <Text
                            ref={text2Ref}
                            tag='p'
                            className={`animText title h7 geoRegular white ${props.winWidth >= 1280 ? topBlocks.second : 'animPlay'}`}
                            text='address'
                        />
                        <Text
                            tag='p'
                            className={`animText title h7 geoRegular white ${props.winWidth >= 1280 ? topBlocks.second : 'animPlay'}`}
                            text={props.data.address}
                        />
                    </div>
                </div>
                <div className='bottom FlexBoxColumn'>
                    <Text
                        tag='p'
                        className={`animText title h7 geoRegular white ${props.winWidth >= 1280 ? topBlocks.first : 'animPlay'}`}
                        text='questionOrIdea'
                    />
                    <Button
                        link={`mailto:${props.data.email}`}
                        type='bigBtn'
                        className={`white ${props.winWidth >= 1280 ? topBlocks.first : 'animPlay'}`}
                        classNameParent='bigBtn'
                        text='discussions'
                    />
                    <div className='socials FlexBox'>
                        <a
                            target='_blank'
                            href={props.data.socials?.twitter}
                            className={`soc animText h7 geoRegular white ${props.winWidth >= 1280 ? topBlocks.first : 'animPlay'}`}
                        >
                            Twitter
                        </a>
                        <a
                            target='_blank'
                            href={props.data.socials?.instagram}
                            className={`soc animText h7 geoRegular white ${props.winWidth >= 1280 ? topBlocks.first : 'animPlay'}`}
                        >
                            Instagram
                        </a>
                        <a
                            target='_blank'
                            href={props.data.socials?.linkedin}
                            className={`soc animText h7 geoRegular white ${props.winWidth >= 1280 ? topBlocks.first : 'animPlay'}`}
                        >
                            Linkedin
                        </a>
                    </div>
                    <div className='ftr FlexBox spaceBetween'>
                        <CustomLink url={config.routes.terms.path}>
                            <Text
                                tag='p'
                                className={`animText h7 geoRegular white customUnderline ${props.winWidth >= 1280 ? topBlocks.first : 'animPlay'}`}
                                text='terms'
                            />
                        </CustomLink>
                        <CustomLink url={config.routes.dataProtection.path}>
                            <Text
                                tag='p'
                                className={`animText h7 geoRegular white customUnderline ${props.winWidth >= 1280 ? topBlocks.first : 'animPlay'}`}
                                text='dataProtection'
                            />
                        </CustomLink>
                        <Text
                            tag={'p'}
                            className={`h7 geoRegular white FlexBox conceptReference alignEnd ${props.winWidth >= 1280 ? topBlocks.first : 'animPlay'
                                }`}
                        >
                            <Translate val={'designedBy'} />
                            <a
                                className='h7 geoRegular uppercase white customUnderline concept'
                                target='_blank'
                                rel='noopener noreferrer'
                                href='https://conceptstudio.com/'
                            >
                                {'  '}
                                Concept Studio
                            </a>
                        </Text>
                    </div>
                </div>
            </div>
            <Image className='pic-1' src='/images/home/lamp.jpg' alt='home-bg' />
            <Image className='pic-2' src='/images/home/tableWithSofa.jpg' alt='home-bg' />
        </FooterSectionStyle>
    )
})

export default withUIContext(FooterSection, ['preloader', 'winWidth'])

import styled from 'styled-components'

const AboutUsSectionStyle = styled.div.attrs((props) => ({}))`
	background-color: var(--black);
	overflow: hidden;
	position: relative;
	min-width: max-content;
	padding-left: var(--containerPaddingHome);

	.aboutTitle1 {
		margin-left: 8%;
	}

	.ab-section-1 {
		padding-top: var(--sp31x);

		.text {
			margin-left: 68vw;
			position: relative;

			&.animPlay {
				svg path {
					stroke-dashoffset: 0;
				}
			}

			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) !important;
				transition-delay: 0s !important;
				width: 130%;
				height: auto;

				path {
					stroke-dasharray: 4700;
					stroke-dashoffset: -4700;
					transition: 1s 1.2s;
				}
			}
		}
	}

	.ab-section-2 {
		margin-left: var(--secToSecMarginHorizontal);

		.pic-1 {
			min-width: 40.6vw;
			padding-top: 100vh;
			transform: scale(0.3) translateX(30%);
			transform-origin: bottom right;
			transition: 0.7s;

			&.active {
				transform: scale(1) translateX(0);
			}
		}

		.pic-2 {
			align-self: flex-end;
			min-width: 23vw;
			padding-top: 28.7vw;
			margin-left: var(--sp7x);
			transform: translateX(50%);
			transition: 0.7s;

			&.active {
				transform: translateX(0);
			}
		}

		.info {
			margin-top: var(--sp45x);
			margin-left: var(--secToSecMarginHorizontal);
			margin-right: var(--secToSecMarginHorizontal);
			max-width: 88vw;

			&.active {
				.title,
				.bottom .text-1,
				.bottom .text-2 {
					transform: scaleX(1) translateX(0);
					opacity: 1;
				}
			}

			.title {
				margin-bottom: var(--sp11x);
				transform: scaleX(1.6) translateX(30%);
				opacity: 0;
				transition: 1s cubic-bezier(0.76, 0, 0.24, 1) 0.5s;
			}

			.bottom {
				max-width: var(--sp221x);
				align-self: flex-end;

				.text-1 {
					margin-right: var(--sp13x);
					transform: scaleX(1.6) translateX(30%);
					opacity: 0;
					transition: 1s cubic-bezier(0.76, 0, 0.24, 1);
				}

				.text-2 {
					transform: scaleX(1.6) translateX(50%);
					opacity: 0;
					transition: 1s cubic-bezier(0.76, 0, 0.24, 1) 0.7s;
				}

				.comp-title {
					flex: 1;
				}
			}
		}
	}

	.ab-section-3 {
		padding-right: var(--containerPaddingHome);

		.pic-1 {
			min-width: 40.6vw;
			padding-top: 100vh;
			transform: scale(0.3) translateX(30%);
			transform-origin: bottom right;
			transition: 0.7s;

			&.active {
				transform: scale(1) translateX(0);
			}
		}

		.info {
			margin-left: var(--secToSecMarginHorizontal);
			margin-top: var(--sp34x);

			&.active {
				.title,
				.text {
					transform: scaleX(1) translateX(0);
					opacity: 1;
				}
			}

			.title {
				margin-bottom: var(--sp11x);
				max-width: var(--sp210x);
				transform: scaleX(1.6) translateX(60%);
				opacity: 0;
				transition: 1s cubic-bezier(0.76, 0, 0.24, 1) 0.7s;
			}

			.text {
				max-width: var(--sp140x);
				margin-left: 33vw;
				transform: scaleX(1.6) translateX(50%);
				opacity: 0;
				transition: 1s cubic-bezier(0.76, 0, 0.24, 1);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		.ab-section-1 {
			padding-top: var(--sp22x);
			.text {
				margin-left: 63vw;
			}
		}

		.ab-section-2 {
			.pic-2 {
				margin-left: var(--sp5x);
			}

			.info {
				margin-top: var(--sp34x);
				max-width: 84vw;

				.title {
					margin-bottom: var(--sp9x);
				}

				.bottom {
					max-width: var(--sp180x);

					.text-1 {
						margin-right: var(--sp10x);
					}
				}
			}
		}

		.ab-section-3 {
			.info {
				margin-top: var(--sp29x);

				.title {
					margin-bottom: var(--sp9x);
					max-width: var(--sp153x);
				}

				.text {
					max-width: var(--sp106x);
					margin-left: 33vw;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		.ab-section-1 {
			padding-top: var(--sp19x);
			.text {
				margin-left: 66vw;
			}
		}

		.ab-section-2 {
			.pic-2 {
				margin-left: var(--sp4x);
			}

			.info {
				margin-top: var(--sp34x);
				max-width: 89vw;

				.title {
					margin-bottom: var(--sp9x);
				}

				.bottom {
					max-width: var(--sp129x);

					.text-1 {
						margin-right: var(--sp9x);
					}
				}
			}
		}

		.ab-section-3 {
			.info {
				margin-top: var(--sp29x);

				.title {
					margin-bottom: var(--sp8x);
					max-width: var(--sp150x);
				}

				.text {
					max-width: var(--sp90x);
					margin-left: 4vw;
				}
			}
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		.ab-section-1 {
			padding-top: var(--sp9x);
			.text {
				margin-left: 66vw;
			}
		}

		.ab-section-2 {
			.pic-2 {
				margin-left: var(--sp3x);
			}

			.info {
				margin-top: var(--sp24x);
				max-width: 89vw;

				.title {
					margin-bottom: var(--sp9x);
				}

				.bottom {
					max-width: var(--sp129x);

					.text-1 {
						margin-right: var(--sp9x);
					}
				}
			}
		}

		.ab-section-3 {
			.info {
				margin-top: var(--sp29x);

				.title {
					margin-bottom: var(--sp8x);
					max-width: var(--sp150x);
				}

				.text {
					max-width: var(--sp90x);
					margin-left: 50vw;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		padding: 0 var(--sp8x);
		padding-bottom: var(--sp15x);
		flex-direction: column;
		min-width: unset;

		.aboutTitle1 {
			margin-left: 0;
		}

		.ab-section-1 {
			padding-top: 0;
			margin-top: var(--sp15x);

			.text {
				margin-left: var(--sp7x);
				margin-top: var(--sp6x);
				align-self: flex-start;
			}
		}

		.ab-section-2 {
			flex-wrap: wrap;
			margin-top: var(--sp15x);

			.pic-1 {
				width: 61.4%;
				padding-top: 76.8%;
				min-width: unset;
			}

			.pic-2 {
				margin-left: var(--sp2-5x);
				width: 36%;
				padding-top: 44.7%;
			}

			.info {
				margin-top: var(--sp12x);
				max-width: unset;

				.title {
					margin-bottom: var(--sp6x);
				}

				.bottom {
					max-width: unset;

					.text-1 {
						margin-right: var(--sp9x);
					}
				}
			}
		}

		.ab-section-3 {
			flex-direction: column;
			margin-top: var(--sp12x);
			padding-right: 0;
			width: 100%;

			.pic-1 {
				width: 61.4%;
				padding-top: 76.8%;
				min-width: unset;
			}

			.info {
				margin-top: var(--sp12x);

				.title {
					margin-bottom: var(--sp6x);
					max-width: unset;
				}

				.text {
					width: 61.4%;
					max-width: unset;
					margin-left: unset;
					align-self: flex-end;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		padding: 0 var(--sp4x);
		padding-bottom: var(--sp15x);
		flex-direction: column;
		min-width: unset;

		.aboutTitle1 {
			margin-left: 0;
		}

		.ab-section-1 {
			padding-top: 0;
			margin-top: var(--sp15x);

			.text {
				margin-left: var(--sp7x);
				margin-top: var(--sp6x);
				align-self: flex-start;
			}
		}

		.ab-section-2 {
			flex-wrap: wrap;
			margin-top: var(--sp15x);

			.pic-1 {
				width: 61.4%;
				padding-top: 76.8%;
				min-width: unset;
			}

			.pic-2 {
				margin-left: var(--sp2-5x);
				width: 35.5%;
				padding-top: 44.7%;
			}

			.info {
				margin-top: var(--sp12x);
				max-width: unset;

				.title {
					margin-bottom: var(--sp6x);
				}

				.bottom {
					max-width: unset;
					flex-direction: column;
					width: 100%;

					.text-1 {
						margin-right: unset;
						max-width: var(--sp54x);
						align-self: flex-start;
					}

					.text-2 {
						max-width: var(--sp54x);
						align-self: flex-end;
						margin-top: var(--sp6x);
					}
				}
			}
		}

		.ab-section-3 {
			flex-direction: column;
			margin-top: var(--sp12x);
			padding-right: 0;
			width: 100%;

			.pic-1 {
				width: 61.4%;
				padding-top: 76.8%;
				min-width: unset;
			}

			.info {
				margin-top: var(--sp12x);

				.title {
					margin-bottom: var(--sp6x);
					max-width: unset;
				}

				.text {
					width: 61.4%;
					max-width: unset;
					margin-left: unset;
					align-self: flex-end;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		padding: 0 var(--sp2x);
		padding-bottom: var(--sp12x);
		flex-direction: column;
		min-width: unset;

		.aboutTitle1 {
			margin-left: 0;
		}

		.ab-section-1 {
			padding-top: 0;
			margin-top: var(--sp12x);

			.text {
				margin-left: var(--sp4x);
				margin-top: var(--sp4x);
				align-self: flex-start;
			}
		}

		.ab-section-2 {
			flex-direction: column;
			margin-top: var(--sp9x);

			.pic-1 {
				width: 100%;
				padding-top: 127.8%;
				min-width: unset;
			}

			.pic-2 {
				align-self: flex-start;
				margin-left: 0;
				width: 58.5%;
				padding-top: 72.7%;
				margin-top: var(--sp2-5x);
			}

			.info {
				margin-top: var(--sp9x);
				max-width: unset;

				.title {
					margin-bottom: var(--sp6x);
				}

				.bottom {
					max-width: unset;
					flex-direction: column;
					width: 100%;

					.text-1 {
						margin-right: unset;
						max-width: unset;
						align-self: flex-start;
					}

					.text-2 {
						max-width: unset;
						align-self: flex-end;
						margin-top: var(--sp6x);
					}
				}
			}
		}

		.ab-section-3 {
			flex-direction: column;
			margin-top: var(--sp9x);
			padding-right: 0;
			width: 100%;

			.pic-1 {
				width: 100%;
				padding-top: 127.8%;
				min-width: unset;
			}

			.info {
				margin-top: var(--sp9x);

				.title {
					margin-bottom: var(--sp6x);
					max-width: unset;
				}

				.text {
					width: 100%;
					max-width: unset;
					margin-left: unset;
					align-self: flex-end;
				}
			}
		}
	}
`

export default AboutUsSectionStyle
